var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',_vm._l((_vm.points),function(coordinate,index){return _c('g',{key:index},[_c('circle',{class:_vm.searchRefs(coordinate.ref) ? 'glowing-circle' : '',attrs:{"id":`g${index}`,"stroke":coordinate.rcolor,"stroke-width":20,"fill":coordinate.pcolor,"cx":_vm.btnRenderSide == 1
          ? _vm.width -
            coordinate.x *
              60 *
              1.5 *
              _vm.svgScale *
              (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1)
          : (coordinate.x * 60 + _vm.x_offset) *
            1.5 *
            _vm.svgScale *
            (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1),"cy":_vm.btnRenderSide == 2
          ? (coordinate.y * -200 + _vm.y_offset * 2) *
            0.4 *
            (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1)
          : _vm.height -
            (coordinate.y * -200 + _vm.y_offset) *
              0.4 *
              (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1),"r":50 * _vm.svgScale * (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1)},on:{"mouseover":function($event){_vm.showTooltip({
          x:
            (coordinate.x * 60 + _vm.x_offset) *
            1.5 *
            _vm.svgScale *
            (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1),
          y: _vm.height * 0.1 * _vm.svgScale - (coordinate.y * 200 + _vm.y_offset) * 0.4,

          text: `Ref: ${coordinate.ref} Net: ${coordinate.net} [x:${coordinate.x} y:${coordinate.y}]`,
        })},"mouseout":_vm.hideTooltip,"click":function($event){return _vm.rotateRef(index)}}}),_c('PivotLabel',{ref:"points",refInFor:true,attrs:{"labelString":coordinate.ref,"xCoord":_vm.btnRenderSide == 1
          ? _vm.width -
            coordinate.x *
              60 *
              1.5 *
              _vm.svgScale *
              (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1)
          : (coordinate.x * 60 + _vm.x_offset) *
            1.5 *
            _vm.svgScale *
            (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1),"yCoord":_vm.btnRenderSide == 2
          ? (coordinate.y * -200 + _vm.y_offset * 2) *
            0.4 *
            (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1)
          : _vm.height -
            (coordinate.y * -200 + _vm.y_offset) *
              0.4 *
              (_vm.sliderScale != 0 ? 1 + _vm.sliderScale * 0.1 : 1),"showRefs":_vm.showRefs,"searchRefString":_vm.searchRefString,"refSlider":_vm.refSlider}})],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }