<template>
  <g>
    <g v-for="(coordinate, index) in points" :key="index">
      <circle
        :id="`g${index}`"
        :stroke="coordinate.rcolor"
        :stroke-width="20"
        :fill="coordinate.pcolor"
        :cx="
          btnRenderSide == 1
            ? width -
              coordinate.x *
                60 *
                1.5 *
                svgScale *
                (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1)
            : (coordinate.x * 60 + x_offset) *
              1.5 *
              svgScale *
              (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1)
        "
        :cy="
          btnRenderSide == 2
            ? (coordinate.y * -200 + y_offset * 2) *
              0.4 *
              (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1)
            : height -
              (coordinate.y * -200 + y_offset) *
                0.4 *
                (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1)
        "
        :r="50 * svgScale * (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1)"
        @mouseover="
          showTooltip({
            x:
              (coordinate.x * 60 + x_offset) *
              1.5 *
              svgScale *
              (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1),
            y: height * 0.1 * svgScale - (coordinate.y * 200 + y_offset) * 0.4,

            text: `Ref: ${coordinate.ref} Net: ${coordinate.net} [x:${coordinate.x} y:${coordinate.y}]`,
          })
        "
        @mouseout="hideTooltip"
        @click="rotateRef(index)"
        :class="searchRefs(coordinate.ref) ? 'glowing-circle' : ''"
      />
      <PivotLabel
        :labelString="coordinate.ref"
        :xCoord="
          btnRenderSide == 1
            ? width -
              coordinate.x *
                60 *
                1.5 *
                svgScale *
                (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1)
            : (coordinate.x * 60 + x_offset) *
              1.5 *
              svgScale *
              (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1)
        "
        :yCoord="
          btnRenderSide == 2
            ? (coordinate.y * -200 + y_offset * 2) *
              0.4 *
              (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1)
            : height -
              (coordinate.y * -200 + y_offset) *
                0.4 *
                (sliderScale != 0 ? 1 + sliderScale * 0.1 : 1)
        "
        :showRefs="showRefs"
        :searchRefString="searchRefString"
        :refSlider="refSlider"
        ref="points"
      />
    </g>
  </g>
</template>
<script>
export default {
  name: "TestPointView",
  props: {
    svgScale: Number,
    x_offset: Number,
    y_offset: Number,
    width: Number,
    height: Number,
    sliderScale: Number,
    btnRenderSide: Number,
    showRefs: Boolean,
    searchRefString: String,
    refSlider: Number,
  },
  components: {
    PivotLabel: () => import("./PivotLabel.vue"),
  },
  data() {
    return {
      click_counter: 0,
    };
  },
  computed: {
    points() {
      return this.$store.getters["svgdrawing/testpointPlot"];
    },
    position() {
      return ["left", "bottom", "right", "top"][this.click_counter];
    },
  },
  methods: {
    activateDragAndDrop() {
      return;
    },
    showTooltip(payload) {
      this.$store.dispatch("ui/hoverText", payload.text);
      this.$emit("toggleHoverText", true);
    },
    hideTooltip() {
      this.$store.dispatch("ui/hoverText", null);
      this.$emit("toggleHoverText", false);
    },
    rotateRef(index) {
      this.click_counter = (this.click_counter + 1) % 4;
      this.$refs.points[index] &&
        this.$refs.points[index].updateLabelPosition(this.position);
    },
    searchRefs(refString) {
      return this.showRefs && this.searchRefString
        ? refString
            .toLowerCase()
            .match(this.searchRefString.toLowerCase())
        : false;
    },
  },
  mounted() {
    this.activateDragAndDrop(false);
  },
};
</script>
<style scoped>
  /* Define the glow effect using CSS filter */
  .glowing-circle {
    filter: drop-shadow(20 20 500px rgb(255, 0, 0)); /* Adjust values for the desired glow effect */
    animation: pulse 1s infinite; /* Adjust animation duration as needed */
  }

  /* Create an animation to make the glow pulse */
  @keyframes pulse {
    0% {
      filter: drop-shadow(20 20 100px rgba(255, 0, 0, 0.7));
    }
    50% {
      filter: drop-shadow(0 0 200px rgb(255, 0, 0)); /* Increase glow intensity */
    }
    100% {
      filter: drop-shadow(0 0 300px rgba(255, 0, 0, 0.7));
    }
  }
</style>